<template>
    <div>
        <!-- begin case-->
        <article class="case page__case">
            <div class="case__container">
                <div class="case__header">
                    <h2 class="case__title">{{currentCase.title}}</h2>
                    <!-- begin author-->
                    <div class="author case__author">
                        <figure class="author__figure">
                            <div class="author__photo" v-html="">
                                <img :src="currentCase.author.photo.url" width="76" height="76"
                                     :alt="currentCase.author.photo.alt"/>
                            </div>
                            <figcaption class="author__caption">
                                <div class="author__name">{{currentCase.author.name}}</div>
                                <div class="author__about">{{currentCase.author.status}}</div>
                                <div class="author__about">{{currentCase.author.city}}</div>
                            </figcaption>
                        </figure>
                        <!-- begin progress-->
                        <div class="progress author__progress">
                            <ul class="progress__items">
                                <li class="progress__item" v-html="currentCase.author.profitBefore"></li>
                                <li class="progress__item" v-html="currentCase.author.profitCurrent"></li>
                                <li class="progress__item"><span class="progress__value">{{currentCase.author.registration}}</span>
                                    - Дата регистрации в
                                    проекте
                                </li>
                            </ul>
                        </div><!-- end progress-->
                    </div><!-- end author-->
                </div>
                <div class="case__main">
                    <div v-html="currentCase.main"></div>
                    <a href="/cabinet" class="button case__button-login button--yellow button--small"
                       type="button"><span
                            class="button__caption">Войти в личный кабинет</span></a>
                </div>
            </div>
        </article>

        <!-- end case-->
        <!-- begin more-cases-->
        <div class="case__container">
            <div class="case__nav">
                <div v-if="prevCase" @click="goToCase(prevCase)" class="case__nav-button case__nav-button--prev">
                    <div class="case__nav-label">
                        <svg class="svg-icon svg-icon--arrow case__nav-arrow">
                            <use xlink:href="@/assets/img/sprite.svg#arrow"></use>
                        </svg>
                        <span>Предыдущий пост</span>
                    </div>
                    <div class="case__nav-info">
                        <div class="case__nav-photo">
                            <img :src="prevCase.author.photo.url" width="90" height="90"
                                 :alt="prevCase.author.photo.alt"/>
                        </div>
                        <div class="case__nav-content">
                            <div class="case__nav-title">
                                {{prevCase.title}}
                            </div>
                            <div class="case__nav-date">{{prevCase.author.registration}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="nextCase" @click="goToCase(nextCase)" class="case__nav-button case__nav-button--next">
                    <div class="case__nav-label">
                        <svg class="svg-icon svg-icon--arrow case__nav-arrow">
                            <use xlink:href="@/assets/img/sprite.svg#arrow"></use>
                        </svg>
                        <span>Следующий пост</span></div>
                    <div class="case__nav-info">
                        <div class="case__nav-photo">
                            <img :src="nextCase.author.photo.url" width="90" height="90"
                                 :alt="nextCase.author.photo.alt"/>
                        </div>
                        <div class="case__nav-content">
                            <div class="case__nav-title">
                                {{nextCase.title}}
                            </div>
                            <div class="case__nav-date">{{nextCase.author.registration}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="more-cases page__more-cases">
            <div class="more-cases__container">
                <h2 class="more-cases__title">Ещё статьи от Platform</h2>
                <VCases :current="id"/>
            </div>
        </section><!-- end more-cases-->
    </div>
</template>

<script>
    import VCases from "@/components/cases/VCases";
    import {userCases} from "./user-cases";

    const casesId = {
        'case-one': 0,
        'case-two': 1
    }
    export default {
        name: "UserCase",
        components: {
            VCases,
        },
        props: {
            id: {}
        },
        data: () => ({casesId}),
        computed: {
            caseId() {
                return casesId[this.id];
            },
            currentCase() {
                return userCases[this.caseId]
            },
            prevCase() {
                return userCases[this.caseId - 1]
            },
            nextCase() {
                return userCases[this.caseId + 1]
            }
        },
        methods: {
            goToCase(targetCase) {
                console.log(targetCase)
                if (!targetCase) return
                this.$router.push({name: 'user-case', params: {id: targetCase.id}})
            }
        }

    }
</script>

<style lang="scss">
    .case__nav {
        margin-bottom: 50px;
        &-button {
            cursor: pointer;
            &--next {
                margin-left: auto;
            }
        }
    }
    .case__image {
        margin-right: auto;
        margin-left: auto;
    }
    

    .case__insert span {
        line-height: 1.66 !important;
        margin-bottom: 5px;

        mark {
            display: inline-block;
            line-height: 1.66;
        }
    }
</style>