<template>
  <div class="cases__items js-masonry">
    <div class="cases__item" v-for="(item, index) of casesList" :key="index">
      <!-- begin c-case-->
      <article class="c-case">
        <div class="c-case__picture">
          <img :src="item.img" alt=""/>
        </div>
        <div class="c-case__content">
          <time class="c-case__date" datetime="2019-10-28">{{ item.date }}</time>
          <h3 class="c-case__title">
            <router-link class="c-case__link" :to="{name: 'user-case', params: {id: item.id}}">{{ item.author }}</router-link>
          </h3>
          <p class="c-case__text">{{ item.text }}</p>
        </div>
      </article><!-- end c-case-->
    </div>
  </div>
</template>

<script>
import cases from "./cases";
import Macy from "macy"


export default {
  name: "VCases",
  props: {
    current: {}
  },
  data() {
    return {
      cases,
    }
  },
  mounted() {
    this.onInit()
  },
  computed: {
    casesList() {
      if (this.current) {
        return this.cases.filter(item => item.id !== this.current)
      }
      return this.cases
    }
  },
  methods: {
    onInit() {
      [].forEach.call(document.querySelectorAll('.js-masonry'), container => {
        let macy = Macy({
          container,
          trueOrder: true,
          waitForImages: false,
          margin: 0,
          columns: 3,
          breakAt: {
            768: 2,
            480: 1
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>