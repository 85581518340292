export default [
    {
        id: "case-one",
        img: require('@/assets/img/cases/case-1/image-6.png'),
        date: "20 августа 2019",
        author: "Скитченко Алена",
        text: "35 лет: 2,7 млн на гимнастике для лица",
        meta: "Денис Антоньян, 35 лет: как перестать сливать свою жизнь и всё изменить пост"
    },
    {
        id: "case-two",
        img: require('@/assets/img/cases/case-2/image-6.png'),
        date: "19 августа 2019",
        author: "Юлия Алексеева",
        text: "30 лет: 2,7 млн на гимнастике для лица",
        meta: "Денис Антоньян, 35 лет: как перестать сливать свою жизнь и всё изменить пост"
    },
/*    {
        id: "case-three",
        img: require('@/assets/img/cases/case-3.jpg'),
        date: "28 октября 2019",
        author: "Юлия Сайфуллина",
        text: "30 лет: 2,7 млн на гимнастике для лица",
        meta: "Денис Антоньян, 35 лет: как перестать сливать свою жизнь и всё изменить пост"
    },
    {
        id: "case-four",
        img: require('@/assets/img/cases/case-3.jpg'),
        date: "28 октября 2019",
        author: "Юлия Сайфуллина",
        text: "30 лет: 2,7 млн на гимнастике для лица",
        meta: "Денис Антоньян, 35 лет: как перестать сливать свою жизнь и всё изменить пост"
    },
    {
        id: "case-five",
        img: require('@/assets/img/cases/case-1.jpg'),
        date: "28 октября 2019",
        author: "Юлия Сайфуллина",
        text: "30 лет: 2,7 млн на гимнастике для лица",
        meta: "Денис Антоньян, 35 лет: как перестать сливать свою жизнь и всё изменить пост"
    },
    {
        id: "case-six",
        img: require('@/assets/img/cases/case-3.jpg'),
        date: "28 октября 2019",
        author: "Юлия Сайфуллина",
        text: "30 лет: 2,7 млн на гимнастике для лица",
        meta: "Денис Антоньян, 35 лет: как перестать сливать свою жизнь и всё изменить пост"
    }*/
]
