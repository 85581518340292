export const userCases = [
    {
        id: 'case-one',
        title: "35 лет: Осталась одна с ребенком, работала в такси, чтобы подарить дочери айфон, но нашла дело мечты и вышла на доход в 100 000 руб. в месяц",
        author: {
            photo: {
                url: require('@/assets/img/cases/case-1/author-logo.png'),
                alt: 'Скитченко Алена'
            },
            name: 'Скитченко Алена 35 лет',
            status: 'Мама троих детей',
            city: 'Кишинев – Сальск - Ростов на Дону',
            profitBefore: `<span class="progress__value">25 000р в мес</span> - доход до проекта`,
            profitCurrent: `<span class="progress__value">100 000р в мес</span> - доход в проекте`,
            registration: '20.08.2019',
        },
        main: `
        <div class="case__row">
                <div class="case__col">
                    <img src="${require('@/assets/img/cases/case-1/image-1.png')}" alt="" class="case__image">
                </div>
                <div class="case__col">
                    <p>
                        Родилась в городе Кишиневе. Когда мне было 5 лет родители развелись.
                        И Мама переехала в небольшой городок Ростовской области.
                        Времена конечно 90 были для нас не из лёгких. <strong>На мне как на ребёнке всегда была
                        ответственность.</strong>
                        Растопить печь углём, постирать, помыть посуду, погулять
                        с сестрой. Мама всегда была строга
                        ко мне. Учиться можно было только
                        без троек, за них меня наказывали.
                        <strong>И всегда говорили - ты можешь больше.</strong>
                    </p>
                </div>
            </div>
            <p>Окончив 11 класс в 2002 году , я поступила в университет в городе Новочеркасск и уехала туда жить . После
                первого курса на каникулах пошла работать в супермаркет кассиром , уж очень хотелось заработать свои
                первые деньги и купить маме подарок .</p>
            <p>Я купила сервиз , до сих у мамы на кухне стоит . На 2 курсе познакомились со своим мужем и уже на третьем
                я вышла замуж . Муж на тот момент только закончил университет и устроился на работу с зарплатой 10 000
                руб
                Жили с родителями . Когда узнали что беременна первым ребёнком , решили брать однокомнатную квартиру в
                долевое , на тот момент он уже зарабатывал побольше ну и крутился помимо работы . </p>
            <p>Я всегда хотела быть женой и мамой . Мне нравилось готовить, убирать . Но конечно круг интересов был
                сужен и скажем так развития особо не было . </p>
            <h2>Я никогда и подумать не могла. Что я смогу продавать ,
                вообще быть в продажах , что буду смм ( таких слов
                ещё никто тогда не знал ) </h2>
            <p>О том что в меня будет личный бренд в Инстаграм, конечно вообще
                и во сне не предполагалось .</p>
            <img src="${require('@/assets/img/cases/case-1/image-2.png')}" alt="" class="case__image">
            <p>Вообщем спокойно протекали будни домохозяйки и мамы троих детей ,
                в уборке и готовке .</p>
            <p class="case__insert">
                <mark>Речи о себе как о личности конечно не было .</mark>
            </p>
            <p>В 2017 году из за измен мужа , я ухожу от него . Ухожу от хорошей финансово обеспеченой жизни с комфортом
                . <strong>Что буду делать и как зарабатывать, я не знала , Но знала что жить так больше не хочу и не
                    могу .</strong></p>
            <p>За 12 лет совместной жизни я не работала и как зарабатывать вообще не могла понять</p>
            <p>Принимаю решение пойти работать в такси . Было конечно очень сложно , было страшно , девушка таксист это
                как приманка . Я работала по 20 часов , что бы были деньги . Я не ела ни спала . Эмоционально я просто
                была выжата ,
                апатия ко всему была . </p>
            <p>Потом я ещё решила выйти на одну работу и совмещать их . В Аэропрот сотрудником по розыску Багажа меня
                взяли . Зарплата 20 000 руб , ночи не спать , люди неадекватные , орут , требуют , вообщем то ещё
                веселье было . </p>
            <h2>Я жила просто на автомате , с мыслью что у меня дети
                которых надо кормить .
            </h2>
            <p>Чтобы подарить дочке айфон на день рождения , я работалав такси
                неделю вообще почти без сна.
            </p>
            <div class="case__row">
                <div class="case__col">
                    <img class="case__image" src="${require('@/assets/img/cases/case-1/image-3.png')}" width="355" height="355"
                         alt=""/></div>
                <div class="case__col">
                    <img class="case__image" src="${require('@/assets/img/cases/case-1/image-4.png')}" width="355" height="355"
                         alt=""/></div>
            </div>
            <p>
                <strong>И в один день , происходит то что я просто не могу стать с кровати .</strong> У меня нет сил .
                Сильное отравление . Больницы , капельницы , неделя , две , три , месяц . Лучше не становится . С одной
                больницы в другую , больничный за больничным . Весь год выясняли что со мной.
            </p>
            <p class="case__insert">
                <mark>Оказалось заболевание крови, (мефломобластный синдром, заболевание косного мозга)</mark>
            </p>
            <p>
                <strong>Лечить можно , но вылечить навсегда невозможно.</strong> Только научится с этим жить с этим . А
                это очень сложно , осозновать что , сколько тебе жить осталось неизвестно, очень давило .
            </p>
            <h2>Но тут я поняла , сколько ни есть моей жизни , значит надо
                ее прожить в удовольствие с кайфом</h2>
            <p>Надо найти себе такое занятие или работу при которой жить на полную катушку
                И конечно кто ищет тот всегда найдёт . Меня очень привлекал сетевой бизнес , но состоятся в нем на
                получалось , так как отказывали все друзья и знакомые . </p>
            <p>И вот тут произошло чудо , я нашла ролик в интернете , в котором парень рассказывал , как можно это
                делать онлайн . То есть построить сетевой бизнес , используя только современные технологии. Так и
                случилась моя любовь с сетевым бизнесом через интернет 😊
            </p>
            <p><strong>Я не знала вообще ничего , всему училась с нуля , как разговаривать.</strong> Как преподносить
                продукт , но при этом кайфовала , мне очень все нравилось .
                Новые знакомства, новые навыки , я начала чувствовать себя нужной , а это очень важно было для меня .
                Появилась своя небольшая команда , доход
            </p>
            <img src="${require('@/assets/img/cases/case-1/image-5.png')}" alt="" class="case__image">
            <p>В 2019 году мои наставники в сетевом бизнесе , решили запустить новый формат сетевого в жизнь . <strong>Был
                создан бизнес проект онлай с интеллектуальным продуктом .</strong> С самым востребованным продуктом
                сейчас на рынке 21 века , века технологий.</p>
            <h2>Онлайн бизнес образовательная площадка с всевозможными курсами по смм , ну и конечно же в формате
                сетевого бизнеса . И я опять всему училась с нуля , </h2>
            <p>Но это настолько интересно, это так затягивает, что я просто сейчас не представляю, своей жизни без соц
                сетей , и все что с ними связано .
            </p>
            <p class="case__insert">
                <mark>Внутренне я изменилась кардинально , ушли страхи ,
                    я не смогу у меня не получится . Появилась уверенность , и с каждым днём она все больше и больше .
                </mark>
            </p>
            <p>
                <strong>А ведь я самая обычная женщина , которая когда то ничего не умела кроме как варить борщи ,
                    убирать дома и вытирать сопли детям .
                </strong>
            </p>
            <p>Теперь я уверенно смотрю в завтрашний день . Я знаю что у меня есть возможность показать мир своим детям
                , сделать так , что бы у них было право выбора и уверенность в себе и завтрашнем дне .
            </p>
            <div class="case__row">
                <div class="case__col">
                    <img src="${require('@/assets/img/cases/case-1/image-6.png')}" alt="" class="case__image">
                </div>
                <div class="case__col">
                    <img src="${require('@/assets/img/cases/case-1/image-7.png')}" alt="" class="case__image">
                </div>
            </div>
`
    },
    {
        id: 'case-two',
        title: "30 лет: Найти силы, чтобы изменить жизнь, даже когда все хорошо, но живешь чужой жизнью. И всего за год заработать 2 000 000 руб.",
        author: {
            photo: {
                url: require('@/assets/img/cases/case-2/author-logo.png'),
                alt: 'Алексеева Юлия'
            },
            name: 'Алексеева Юлия 30 лет',
            status: 'Руководитель отдела магазиностроения',
            city: 'г. Ярославль',
            profitBefore: `<span class="progress__value">35 000 - 55 000 руб. мес</span> - доход до проекта`,
            profitCurrent: `<span class="progress__value">203 000р в мес</span> - Максимальный чек на Платформе`,
            registration: '20.08.2019',
        },
        main: `
        <div class="case__row">
                <div class="case__col">
                    <img src="${require('@/assets/img/cases/case-2/image-1.png')}" alt="" class="case__image">
                </div>
                <div class="case__col">
                    <p>В моей жизни не было венораздирающих историй потерь и лишений, я не падала на дно, чтобы оттолкнуться и подняться вверх...
Я росла в окружении прекрасных, добрых, хорошо образованных людей. 
                    </p>
                    <p>
                    Мама-главбух, папа на заводе работает и покоряет Антарктиду, бабушки с дедушками-учитель, директор школы, главный агроном единственного в области тепличного комбината, заведующая магазина.
Мы всегда жили хорошо. Может, чууууточку лучше многих - мы никогда не испытывали нужду, но и не шиковали.
</p>
                  
                </div>
            </div>
        <p>Я была осознанным и самостоятельным ребёнком. Работала в летние каникулы с 15 лет, чтобы самой покупать себе всякие девчачьи штучки😄</p>
            <p><strong>Помню, как купила сама себе первый мобильный телефон - красную «раскладушку» Nokia. Очень гордилась этим достижением!
                    </strong>
            </p>
            <p><strong>Школу я окончила с золотой медалью</strong>, поступила на бесплатной основе в хороший университет, и ближе к лету по традиции начала подыскивать подработку. И тут каким-то непостижимым образом я попала в компанию Орифлейм. Именно здесь я познала все прелести того самого сетевого маркетинга, от которого все шарахаются😆 </p>
            <p>Но тогда я об этом не думала вообще. Я спросила: что мне надо сделать, чтобы заработать 30 тысяч рублей на поездку в Питер? Мне сказали. И я начала делать.</p>
            <div class="case__row">
                    <img src="${require('@/assets/img/cases/case-2/image-2.png')}" class="case__image"/>
                <div class="case__col">
                            <p><strong>Я раздавала каталоги на улице</strong> и ходила с ними 
                            по офисам, собирала и разносила заказы, стояла в торговых центрах и на улице с промостойками, проводила мастер-классы по уходу за руками 
                            и лицом, постоянно обзванивала незнакомых людей и предлагала оформить дисконтную карту.</p>
                            <p>В общем, максимальный выход из зоны комфорта.</p>
                            <p class="case__insert">
                            <mark>Но цель была достигнута: я поехала 
                                через 3 месяца в город на Неве 
                                на заработанные деньги.
                                </mark>
                            </p>
                </div>
            </div>
            <div class="case__row">
                <div class="case__col">
                    <p>А дальше рост в квалификациях, признание,  «банкет директоров» в Олимпийском: 
                    ты выходишь на сцену и тебе аплодируют 
                    10 тысяч человек, это ни с чем не сравнимые эмоции!!! 
                    </p>
                    <p>
                        Я очень благодарна этому опыту 
                        в Орифлейм: я смогла построить команду, приобрела огромное количество полезных навыков, прокачала себя и, самое главное, 
                        <strong>я ощутила на себе прелесть сетевых денег,
                         и поняла, что работа с 9 до 18 5/2 - это не единственный жизненный сценарий.</strong>
                    </p>
                </div>
                    <img src="${require('@/assets/img/cases/case-2/image-3.png')}" alt="" class="case__image">
            </div>
            <div class="case__row">
                    <img src="${require('@/assets/img/cases/case-2/image-4.png')}" alt="" class="case__image">
                <div class="case__col">
                    <p>
                        Во всем этом было одно большое «НО».
                        <strong>Те методы, которыми мы работали, очень быстро перестали вписываться в мою картину мира. </strong>
                    </p>
                    <p>
                        Я поняла, что не готова связывать свою жизнь 
                        с бесконечными каталогами, закупами продукции, промостойками и тд. Я все это переросла. 
                        А альтернатив не предлагалось. На этом с сетевым бизнесом я решила попрощаться.
                    </p>
                </div>
            </div>
            <h2>В это время я как раз окончила университет, и подумала, 
                что девочка с красным дипломом обязательно должна поработать в найме
            </h2>
            <p>
                Все же так делают. <strong>Но вот беда: никому я оказалась не нужна без опыта работы по специальности</strong>, и мои мечты о сказочной жизни постоянно путешествующего переводчика рухнули, как карточный домик.
            </p>
            <p>
                       Я устроилась на должность помощника руководителя, через 9 месяцев меня повысили до менеджера отдела магазиностроения. 
                    </p>
                    <p>
                        Так за очень короткий срок из переводчика 
                        я переквалифицировалась 
                        в дизайнера-проектировщика торгово-холодильного оборудования 😆 через три года стала руководителем этого отдела.... и вроде бы все хорошо: прекрасный муж, стабильная офисная работа... 
                    </p>
            <p class="case__insert">
                <mark>Да, ужасная нервотрёпка, огромная 
                    ответственность и совсем небольшая зарплата...
                </mark>
            </p>
            <p>Да, отпуск 2 раза в год, в котором ты сидишь, как на иголках, в ожидании очередного косяка у клиента и звонка директора... Но ведь у всех так!! У многих даже хуже!🤷🏻♀️</p>
            <h2>Я ПОСТОЯННО ОЩУЩАЛА, ЧТО ЖИВУ НЕ СВОЕЙ, 
БУДТО БЫ, ЖИЗНЬЮ...😒</h2>
            <p>
                <strong>Я с детства очень много мечтала...</strong> В своих фантазиях я была кто угодно: актриса, певица, художник, модель, переводчик мирового <уровня class=". strong">но никак не самая обычная девушка, работающая за 40 тысяч в месяц на нелюбимой работе, живущая на окраине города в ипотечной квартире...</уровня>🙁
            </p>
            <p class="case__insert">
                <mark>   Огонь в моих глазах затухал, я все реже улыбалась, 
                  бытовуха и «день сурка» засасывали, я осознала, что 
                  из амбициозной, яркой, веселой мечтательницы 
                  я превратилась в серую массу, живущую по схеме: 
                  дом, работа, дом, по выходным-родители и Ашан... </mark>
            </p>
            <p><strong>Я СТАЛА КАК ВСЕ...</strong></p>
            <div class="case__row">
                <img src="${require('@/assets/img/cases/case-2/image-5.png')}" alt="" class="case__image">
                <div class="case__col">
                    <p><strong>И СТАЛО СТРАШНО!</strong> Я понимала, что иду не туда, не свои мечты осуществляю, не на то трачу своё драгоценное время жизни...
                        </p>
                    <p>В это время на горизонте появилась компания NL international. Красивые картинки в Инстаграм, дорогие тачки, путешествия, брендовые шмотки, заоблачные чеки...
                    </p>
                 </div>
            </div>
            <p>Опять сетевой???😳 А может... А почему бы и нет? Но блин, так страшно после первого раза...да и столько лет прошло.... Тут нет каталогов? Нет промостоек? Тут 
                уже новый век, новое поколение крутых и стильных сетевиков? Надо понаблюдать. 
                <strong>На моих глазах в чеках менеджеров этой компании появлялись все новые нули, 
                а я сидела все в том же офисе, чертила все те же магазины и ничего, ровным счетом, не менялось...</strong>
            </p>
            <div class="case__row">
                <img src="${require('@/assets/img/cases/case-2/image-6.png')}" alt="" class="case__image">
                <div class="case__col">
                    <p>Долгожданный декрет: на свет появилась наша удивительно-прекрасная дочь. Бесконечное счастье, радость первой встречи, первые шаги, умиление, желание быть </p>
                    <p>
                        План в голове уже был. Иду опять в сетевой - иду в NL. <strong>Причин две:</strong>
                        1. У меня нет ни идей, ни денег, ни желания на открытие своего бизнеса.
                        <strong>2. Я уже точно знаю на личном опыте, что в сетевом есть деньги. Большие деньги. Я знаю, что это максимально реальный шанс заработать абсолютно всем, а особенно мамам в декрете.</strong>
                    </p>
                </div>
            </div>
            <div>Зарегистрировалась в самую крутую, по тем временам, команду.</div>
            <div>Первый вопрос:</div>
            <p class="case__insert" style="font-size: 16px; display: flex; flex-direction: column">
                <span><mark style="background: #EFFDDE">- Продавать надо?</mark></span>
                <span>- Нет</span>
                <span><mark style="background: #EFFDDE">- Отлично, беру только себе в личное пользование.</mark></span>
            </p>
            <p>Второй вопрос:</p>
             <p class="case__insert" style="font-size: 16px; display: flex; flex-direction: column">
                <span><mark style="background: #EFFDDE">- знакомых можно не приглашать?</mark></span>
                <span>- Можно</span>
                <span><mark style="background: #EFFDDE">- Фух! Погнали!</mark></span>
            </p>
            <p>Закупила продукт, получила обучающие материалы.<br/> Теория супер! А как насчёт практики?</p>
             <p class="case__insert" style="font-size: 16px; display: flex; flex-direction: column">
                <span><mark style="background: #EFFDDE">- Что нужно сделать, чтобы люди сами приходили в команду?</mark></span>
                <span>- Показать первый результат.</span>
                <span><mark style="background: #EFFDDE">- А как?</mark></span>
                <span>- Продай на 24 тысячи, получишь 8.</span>
                <span><mark style="background: #EFFDDE">- Но ведь говорили, что продавать не надо...</mark></span>
                <span>- Иначе никак.</span>
            </p>
            <p>Ок, еле-еле продала</p>
             <p class="case__insert" style="font-size: 16px; display: flex; flex-direction: column">
                <span><mark style="background: #EFFDDE">- А как быть с командой? Люди не идут... Знакомых звать</mark></span>
                <span><mark style="background: #EFFDDE">не буду-это точно не мой путь. Я и в Орифлейм так не делала.</mark></span>
                <span><mark style="background: #EFFDDE">Какие варианты?</mark></span>
                <span>- Надо вести крутой Инстаграм. Вот курсы по мобильной фотографии и по продвижению Инстаграм. 5 тысяч рублей. Проходи и начинай.
                </span>
                <span><mark style="background: #EFFDDE">- Но ведь чтобы вести интересный Инстаграм, надо чтобы жизнь</mark></span>
                 <span><mark style="background: #EFFDDE">была интересной, а я дома с ребёнком грудным сижу.</mark></span>
                <span><strong>- Твои проблемы. Думай.</strong></span>
            </p>
            <p>Думаю. Делаю. Несколько месяцев ежедневного ведения инсты, 5 человек в команде. Первая квалификация. Первый раз вышла в плюс. Осознаю, что двух лет декрета мне точно не хватит. Безнадёга. </p>
            <div class="case__row">
                <div class="case__col">
                    <p><strong>Волей судьбы узнала про проект Netsurfing</strong>, в котором люди строят этот 
                        же бизнес с этой же компанией, но без списка знакомых, звонков, встреч и продаж - исключительно через интернет.
                    </p>
                    <p>Вот он - мой шанс? схватилась за него 
                        со всей силы: ту квалификацию, которую первый раз я закрывала полгода, тут закрыла в первый месяц. <strong>На пятый месяц команда 270 человек, доход 27 тысяч рублей. На седьмой месяц 400 человек, доход 35 тысяч.</strong> Ни одного знакомого 
                        в команде, ни одной продажи продукта, ни одной встречи в офисе 😍
                    </p>
                </div>
                <img src="${require('@/assets/img/cases/case-2/image-7.png')}" alt="" class="case__image">
            </div>
            <p>Но и тут возникает одно большое «НО». 
Люди в моей команде из месяца в месяц 
зарабатывали гораздо меньше, чем тратили. Ежемесячная покупка не всегда нужного в таком количестве продукта на 8 тысяч рублей давалась всем ой, как нелегко. <strong>По этой причине люди уходили из бизнеса, на их место приходили новые и спустя пару месяцев тоже уходили...</strong></p>
            <h2>Нужно было срочно что-то менять, но что? Этот вопрос зрел 
не только у меня, но и у основателей проекта.</h2>
            <p>И однажды на вебинаре было объявлено: мы уходим из NL и организуем свою собственную компанию, которая позволит всем без исключения зарабатывать исключительно в интернете, без закупа ненужной продукции</p>
            <p style="text-transform: uppercase"><strong>Бинго! Это именно то, что было сейчас нужно!</strong></p>
            <p>❌ Никакой привязки к географии</p>
            <p>❌ Никаких заморочек с покупкой и доставкой продукций</p>
            <p>✅ Простые алгоритмы доступные абсолютно любому человеку, 
независимо от рода его деятельности, возраста и опыта работы</p>
            <p>✅ Актуальный, классный информационный продукт
</p>
            <p>✅ Самый выгодный маркетинг-план</p>
            <p>✅ Огромное количество методов работы</p>
            <h2>И вот я в компании PLATFORM!)</h2>
            <p>Никогда бы не подумала, что пресловутое выражение «работа в интернете» будет хоть каким-то образом относиться ко мне... Но именно так можно описать то, что происходит в моей жизни.</p>
            <div class="case__row">
                <img src="${require('@/assets/img/cases/case-2/image-8.png')}" alt="" class="case__image" style="width: 242px; height: 396px">
                <div class="case__col">
                    <p><strong>Я действительно работаю дома, и для этого мне нужен только мой смартфон.</strong> Я провожу много времени с семьей, у меня нет  начальника, я уже 
                        не помню, как это - просыпаться по будильнику.
                    </p>
                    <p>Я стала свободной финансово.</p>
                    <p style="font-size: 22px">
                        <span><mark>За 1 год сотрудничества с проектом я заработала более 2 000 000 рублей</mark></span>
                    </p>
                    <p>бОльшую часть из которых я отложила на ремонт моей мечты.  Я уволилась из найма,и это был день, 
                        о котором я мечтала несколько последних лет!
                     </p>
                </div>
            </div>
            <h2>За этот год я дважды была заграницей сама и оплатила 
отдых своим родителям</h2>
            <p>В феврале я побывала на о. Бали за счет нашей компании. Это было самое незабываемое путешествие в моей жизни!Я никогда не видела таких красивых пейзажей, такой природы, таких потрясающих закатов, такого теплого океана…</p>
            <p class="case__row">
                <span class="case__col"><img src="${require('@/assets/img/cases/case-2/image-9.png')}"/></span>
                <span class="case__col"><img src="${require('@/assets/img/cases/case-2/image-10.png')}"/></span>
            </p>
            <h2>В сентябре всей семьей мы отдыхали в отличном отеле в Крыму. Моя мама и моя дочь впервые полетели на самолете, 
и я счастлива, что смогла осуществить их мечту и оплатить такой отдых! </h2>
            <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 12px">
                <img src="${require('@/assets/img/cases/case-2/image-11.png')}"/>
                <img src="${require('@/assets/img/cases/case-2/image-12.png')}"/>
                <img src="${require('@/assets/img/cases/case-2/image-13.png')}"/>
            </div>
            <p>Сейчас я чувствую, что я на своём месте. Я знаю, куда и зачем иду, мне нравится не только конечная цель, но и путь к ней, меня окружают такие же заряженные на успех, дерзкие, смелые мечтатели, с которыми мы вместе идем к нашим целям, с которыми мы стали уже одной большой семьей.</p>
         `
    }
]
